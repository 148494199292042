<template>
  <div>
    <KTCodePreview v-bind:title="billDetailTitle">
      <template v-slot:preview>
        <b-form ref="form" lazy-validation class="row">
          <b-container class="bv-example-row">
            <b-row>
              <b-col class="col-md-3">
                <b-form-group>
                  <label>Cửa hàng:</label>
                  <vue-autosuggest
                    :suggestions="filteredOptions"
                    :limit="10"
                    :input-props="{
                      class: 'autosuggest__input',
                      placeholder: 'Tên cửa hàng',
                      disabled: true,
                    }"
                    :should-render-suggestions="
                      (size, loading) => size >= 0 && !loading
                    "
                    v-model="searchStore"
                  >
                    <template slot-scope="{ suggestion }">{{
                      suggestion.item.name
                    }}</template>
                  </vue-autosuggest>
                </b-form-group>

                <b-form-group>
                  <label>Phí trả hàng:</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="returnFee"
                    placeholder="Phí trả hàng"
                    v-mask="mask"
                    :disabled="!editable"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <label>Nhân viên thu ngân:</label>
                  <vue-autosuggest
                    :suggestions="filteredOptionsEmployee"
                    @selected="onSelectedCashier"
                    :limit="100"
                    @input="onInputChangeCashier"
                    :input-props="inputPropCashier"
                    v-model="cashierName"
                  >
                    <template slot-scope="{ suggestion }">{{
                      suggestion.item.fullName
                    }}</template>
                  </vue-autosuggest>
                </b-form-group>

                <b-form-group>
                  <label>Ghi chú bán hàng:</label>
                  <b-form-textarea
                    size="sm"
                    class="input-style"
                    v-model="customerNote"
                    :placeholder="'Thêm ghi chú'"
                    :rows="6"
                    :max-rows="6"
                    :disabled="!editable"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col class="col-md-3">
                <b-form-group>
                  <label>Họ và tên:</label>
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="customerName"
                    placeholder="Họ tên khách hàng"
                    :disabled="true"
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <label>Điện thoại:</label>
                  <vue-autosuggest
                    :suggestions="filteredOptionsCustomer"
                    :limit="100"
                    :input-props="{
                      class: 'autosuggest__input',
                      placeholder: 'SĐT Khách hàng',
                      disabled: true,
                    }"
                    v-model="searchCustomer"
                  >
                    <template slot-scope="{ suggestion }">{{
                      suggestion.item.phoneNo
                    }}</template>
                  </vue-autosuggest>
                </b-form-group>

                <b-form-group>
                  <label>Địa chỉ:</label>
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="address"
                    placeholder="Địa chỉ khách hàng"
                    :disabled="true"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <label>Ghi chú nội bộ:</label>
                  <b-form-textarea
                    size="sm"
                    class="input-style"
                    v-model="saleNote"
                    :placeholder="'Thêm ghi chú'"
                    :rows="6"
                    :max-rows="6"
                    :disabled="!editable"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col class="col-md-3">
                <b-form-group>
                  <label>Trả hàng từ hóa đơn:</label>
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="relateBillNumber"
                    placeholder="Mã hóa đơn"
                    :disabled="true"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <label>Đã sử dụng điểm tích lũy:</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="subPoint"
                    placeholder="Số điểm đã tiêu"
                    :disabled="true"
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <label>Được cộng điểm tích lũy:</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="addPoint"
                    placeholder="Số điểm được cộng"
                    :disabled="true"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="col-md-3">
                <b-form-group>
                  <label>Tiền mặt trả khách:</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="cash2Pay"
                    placeholder="Nhập tiền mặt trả khách"
                    v-mask="mask"
                    :disabled="!editable"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <label>Tài khoản trả tiền:</label>
                  <vue-autosuggest
                    v-model="payAccount"
                    :suggestions="filteredCashAccOptions"
                    @selected="onCashAccSelected"
                    :limit="100"
                    @input="onInputCashAccChange"
                    :input-props="inputPropCash"
                  >
                    <template slot-scope="{ suggestion }">{{
                      suggestion.item.name
                    }}</template>
                  </vue-autosuggest>
                </b-form-group>

                <b-form-group>
                  <label>Tiền chuyển khoản trả khách:</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="cashTransfer2Pay"
                    placeholder="Nhập tiền chuyển khoản trả khách"
                    v-mask="mask"
                    :disabled="!editable"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <label>Tài khoản chuyển khoản:</label>
                  <vue-autosuggest
                    v-model="transferAccount"
                    :suggestions="filteredAccOptions"
                    @selected="onAccSelected"
                    :limit="100"
                    @input="onInputAccChange"
                    :input-props="inputPropTransfer"
                  >
                    <template slot-scope="{ suggestion }">{{
                      suggestion.item.name
                    }}</template>
                  </vue-autosuggest>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <table
                  class="table table-bordered table-vertical-center table-hover"
                >
                  <thead>
                    <tr>
                      <th class="headerTable" style="textalign: center">STT</th>
                      <th class="headerTable">Sản phẩm</th>
                      <th class="headerTable">Số lượng</th>
                      <th class="headerTable">Giá</th>
                      <th class="headerTable">CK phân bổ</th>
                      <th class="headerTable">Tiêu điểm phân bổ</th>
                      <th class="headerTable">Thành tiền</th>
                      <th class="headerTable">Chiết khấu</th>
                      <th v-if="false"></th>
                    </tr>
                  </thead>

                  <tbody v-for="(item, index) in listBillItem" :key="item.id">
                    <ReturnBillItem
                      v-bind:productItem="item"
                      v-bind:index="index"
                      v-bind:editable="false"
                      v-on:deleteItem="deleteItemInArray"
                      v-on:update="updateItem"
                      v-on:checkValidImei="checkValidImei"
                    />
                  </tbody>
                  <tfoot>
                    <tr>
                      <th style="text-align: right" colspan="4">
                        Tổng phải trả:
                      </th>
                      <td style="text-align: right; padding: 0px 20px">
                        {{ convertPrice(totalPrice) }}
                      </td>
                      <td></td>
                      <td v-if="false"></td>
                    </tr>
                  </tfoot>
                </table>
              </b-col>
            </b-row>
          </b-container>
          <b-container
            class="bv-example-row"
            v-if="checkPermission('TRANSACTION_VIEW')"
          >
            <hr
              class="hr-text"
              data-content="Bút Toán"
              style="font-weight: 600"
            />
            <b-row>
              <b-col md="12" lg="12" sm="12">
                <BillTransactions :billNumber="$route.query.id" />
              </b-col>
            </b-row>
          </b-container>
        </b-form>
      </template>

      <template v-slot:foot>
        <b-container class="bv-example-row">
          <b-row>
            <b-col>
              <b-button
                tabindex="15"
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                type="submit"
                @click="updateBill"
                >Lưu</b-button
              >
              <router-link to="/orders" tag="button">
                <b-button
                  tabindex="16"
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  >Hủy</b-button
                >
              </router-link>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </KTCodePreview>
  </div>
</template>
<style scoped>
.form-group label {
  font-weight: 500;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import ApiService from '@/core/services/api.service';
import { currencyMask, unMaskPrice } from './../../../utils/common';
import { validationMixin } from 'vuelidate';
import { removeAccents } from '../../../utils/common';
// import Swal from 'sweetalert2';
import ReturnBillItem from '../../components/ReturnBillItem.vue';
import { xoa_dau } from './../../../utils/common';
import BillTransactions from '../../components/BillTransactions.vue';
import localData from '../../../utils/saveDataToLocal';
import { AMOUNT_TYPE } from '../../../utils/enum';

export default {
  mixins: [validationMixin],
  data() {
    return {
      selectedCasherId: '',
      selectedAccId: '',
      selectedCashId: '',
      cashAccOptions: [
        {
          data: [],
        },
      ],
      filteredCashAccOptions: [],
      accountantOptions: [
        {
          data: [],
        },
      ],
      filteredAccOptions: [],
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      selectedStore: '',
      searchStore: '',
      cashierName: '',
      filteredOptionsEmployee: [],
      optionsEmployee: [
        {
          data: [],
        },
      ],
      customerNote: '',
      searchCustomer: '',
      customerName: '',
      address: '',
      mask: currencyMask,
      cash2Pay: 0,
      payAccount: '',
      cashTransfer2Pay: 0,
      transferAccount: '',
      searchProduct: '',
      listBillItem: [],
      returnFee: 0,
      billId: null,
      billNumber: null,
      proSTT: 0,
      saleNote: '',
      relateBillNumber: '',
      addPoint: 0,
      subPoint: 0,
      billDetailTitle: 'Phiếu trả hàng',
      editable: true,
      inputPropCash: {
        class: 'autosuggest__input',
        placeholder: 'Tài khoản tiền mặt',
        disabled: false,
      },
      inputPropTransfer: {
        class: 'autosuggest__input',
        placeholder: 'Tài khoản chuyển khoản',
        disabled: false,
      },
      inputPropCashier: {
        class: 'autosuggest__input',
        placeholder: 'Nhân viên thu ngân',
        disabled: false,
      },
      filteredOptionsCustomer: [],
    };
  },
  methods: {
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    updateItem(item) {
      this.listBillItem.forEach((element, index) => {
        if (element.id === item.id) {
          this.listBillItem[index].name = item.name;
          this.listBillItem[index].imeiCode = item.imeiCode;
          this.listBillItem[index].proId = item.proId;
          this.listBillItem[index].price = parseInt(item.price);
          this.listBillItem[index].quantity = parseInt(item.quantity);
          this.listBillItem[index].totalPrice = item.totalPrice;
          this.listBillItem[index].discount = item.discount;
        }
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listBillItem.length; i++) {
        if (this.listBillItem[i].id === id) {
          this.listBillItem.splice(i, 1);
        }
      }
    },
    checkValidImei(value) {
      this.isValidImei = value;
    },
    fetchCashAcc: async function () {
      this.listCashAccSearch = [];
      if (this.selectedStore !== '') {
        const params = {
          storeId: this.selectedStore,
          //  tk tien mat
          type: 1,
        };
        await ApiService.query('accountants/getByStore', { params }).then(
          (response) => {
            this.cashAccOptions[0].data = [];
            response.data.data.forEach((item) => {
              let acc = {
                id: item.id,
                name: item.name,
                code: item.code,
              };
              this.cashAccOptions[0].data.push(acc);
            });
            this.filteredCashAccOptions = [
              { data: this.cashAccOptions[0].data },
            ];
          }
        );
      } else {
        const params = {
          //  tk tien mat
          type: 1,
        };
        await ApiService.query('accountants/getByType', { params }).then(
          (response) => {
            this.cashAccOptions[0].data = [];
            response.data.data.forEach((item) => {
              const acc = {
                id: item.id,
                name: item.name,
                code: item.code,
              };
              this.cashAccOptions[0].data.push(acc);
            });
            this.filteredCashAccOptions = [
              { data: this.cashAccOptions[0].data },
            ];
          }
        );
      }
    },
    onInputCashAccChange(text) {
      this.payAccount = text;

      const filteredData = this.cashAccOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCashAccOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onCashAccSelected(option) {
      this.payAccount = option.item.name;
      this.selectedCashId = option.item.id;
    },
    onInputAccChange(text) {
      this.transferAccount = text;

      const filteredData = this.accountantOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredAccOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onAccSelected(option) {
      this.transferAccount = option.item.name;
      this.selectedAccId = option.item.id;
    },
    fetchAccountant: async function () {
      ApiService.setHeader();
      const params = {
        //  tk ngan hang
        type: 2,
      };
      await ApiService.query('accountants/getByType', { params }).then(
        (response) => {
          this.accountantOptions[0].data = [];
          response.data.data.forEach((item) => {
            if (item.level >= 2) {
              const acc = {
                id: item.id,
                name: item.name,
                code: item.code,
              };

              this.accountantOptions[0].data.push(acc);
            }
          });
          this.filteredAccOptions = [{ data: this.accountantOptions[0].data }];
        }
      );
    },
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    updateBill: async function () {
      const listPro = this.listBillItem.map((element) => {
        return {
          productId: element.productId,
          productPrice: unMaskPrice(element.price),
          quantity: element.quantity,
          discountAmount: unMaskPrice(element.discount),
          imeiCode: element.imeiCode,
        };
      });
      const data = {
        id: this.billId,
        storeId: this.selectedStore,
        customerNote: this.customerNote,
        customerName: this.customerName,
        customerPhone: this.searchCustomer,
        address: this.address,
        saleNote: this.saleNote,
        cashAmount: unMaskPrice(this.cash2Pay),
        selectedCashId: this.selectedCashId,
        transferAmount: unMaskPrice(this.cashTransfer2Pay),
        transferAccountID: this.selectedAccId,
        listBillItem: listPro,
        returnFee: unMaskPrice(this.returnFee),
        cashierId: this.selectedCasherId,
      };
      ApiService.put('bills/updateReturnBill', data).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.$router.push({
            path: '/returnBills',
          });
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    onSelectedCashier(option) {
      this.cashierName = option.item.fullName;
      this.selectedCasherId = option.item.id;
    },
    onInputChangeCashier(text) {
      if (!text) {
        text = '';
      }
      this.cashierName = text;
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item.fullName);
          return (
            item.fullName.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    getListEmployee() {
      ApiService.get('employees/getAll').then((data) => {
        const employees = data.data.data;
        employees.map((element) => {
          this.optionsEmployee[0].data.push(element);
        });
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    getBillById() {
      this.onLoading = true;
      ApiService.get(`bills/${this.billNumber}`).then((data) => {
        const relateBill = data.data.data.relateBill;
        this.relateBillNumber = relateBill ? relateBill.billNumber : '';
        this.addPoint = relateBill ? relateBill.addPoint : 0;
        this.subPoint = relateBill ? relateBill.subtractPoint : 0;
        const totalSaleAmount = relateBill ? relateBill.totalAmount : 0;
        const totalSaleDiscountAmount = relateBill
          ? relateBill.discountValue
          : 0;
        const pointAmount = relateBill ? relateBill.pointAmount : 0;
        data.data.data.billItems.forEach((element) => {
          if (element.billItemType === 1) {
            const rate =
              (element.quantity * element.productPrice) / totalSaleAmount;
            const allotmentDiscount = Math.ceil(
              totalSaleDiscountAmount * rate.toFixed(1)
            );
            const allotmentPointAmount = Math.ceil(
              pointAmount * rate.toFixed(1)
            );
            this.proSTT++;
            const billItem = {
              id: element.id,
              productId: element.productId,
              name: element.productName,
              code: element.productCode,
              price: element.productPrice,
              type: element.productType,
              quantity: element.quantity,
              imeiCode: element.imeiCode,
              totalPrice:
                element.quantity * element.productPrice -
                element.discountAmount,
              discount: element.discountAmount,
              discountType: element.discountType,
              comboItems: element.listProductInCombo,
              billItemType: 1,
              checked: true,
              returnPrice: 0,
              accessoryGroupId: '',
              accessoryGroupCode: '',
              stt: this.proSTT,
              note: element.note,
              saleNote: element.saleNote,
              parentId: element.belongBillDetailId,
              listAccessoryPromotion: element.listAccessoryPromotion,
              quantityInStock: element.quantityInStock,
              allotmentDiscount: this.roundMoney(allotmentDiscount),
              allotmentPointAmount: this.roundMoney(allotmentPointAmount),
            };
            this.listBillItem.push(billItem);
          } else {
            let code = `Quà tặng kèm- ${element.accessoryGroupCode}`;
            if (element.billItemType === 3) {
              code = `SP bán kèm- ${element.accessoryGroupCode}`;
            }
            const billItem = {
              id: element.id,
              productId: element.productId,
              name: element.productName,
              code: code,
              price: element.productPrice,
              type: element.productType,
              quantity: element.quantity,
              imeiCode: element.imeiCode,
              totalPrice:
                element.quantity * element.productPrice -
                element.discountAmount,
              discount: element.discountAmount,
              discountType: AMOUNT_TYPE.MONEY,
              billItemType: element.billItemType,
              checked: element.isGiftTaken,
              returnPrice: element.repurchasePrice,
              accessoryGroupId: element.accessoryGroupId,
              accessoryGroupCode: element.accessoryGroupCode,
              parentId: element.belongBillDetailId,
              quantityInStock: element.quantityInStock,
              allotmentDiscount: 0,
              allotmentPointAmount: 0,
            };
            this.listBillItem.push(billItem);
          }
        });
        this.customerNote = data.data.data.customerNote;
        this.selectedCasherId = data.data.data.cashierId;
        this.cashierName = data.data.data.cashierName;
        this.searchCustomer = data.data.data.customerPhone;
        this.address = data.data.data.customerAddress;
        this.customerName = data.data.data.customerName;
        this.searchStore = data.data.data.storeName;
        this.selectedStore = data.data.data.storeId;
        this.cash2Pay = data.data.data.cashAmount;
        this.cashTransfer2Pay = data.data.data.transferAmount;
        this.transferAccount = data.data.data.transferAccName;
        this.payAccount = data.data.data.cashAccName;
        this.billId = data.data.data.id;
        this.fetchAccountant();
        this.fetchCashAcc();
        this.onLoading = false;
        this.returnFee = data.data.data.returnFee;
        this.selectedCashId = data.data.data.cashAccountId;
        this.selectedAccId = data.data.data.transferAccountId;
        this.selectedCasherId = data.data.data.cashierId;
        this.billDetailTitle = `Phiếu trả hàng: #${this.billNumber} - Ngày: ${data.data.data.createdAt}`;
        this.editable = data.data.data.companyId === 3 ? true : false;
        this.inputPropCash.disabled = !this.editable;
        this.inputPropTransfer.disabled = !this.editable;
        this.inputPropCashier.disabled = !this.editable;
      });
    },
    roundMoney(value) {
      const tmpValue = Math.round(value / 1000);
      return tmpValue * 1000;
    },
  },
  components: {
    KTCodePreview,
    ReturnBillItem,
    BillTransactions,
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.billItemType === 1) {
          const totalPrice = unMaskPrice(element.price) * element.quantity;
          // Check discount type
          if (parseInt(element.discountType) === AMOUNT_TYPE.PERCENT) {
            let discountAmount = totalPrice * unMaskPrice(element.discount);
            total +=
              totalPrice -
              (discountAmount / 100).toFixed(0) -
              element.allotmentDiscount -
              element.allotmentPointAmount;
          } else {
            total +=
              totalPrice -
              unMaskPrice(element.discount) -
              element.allotmentDiscount -
              element.allotmentPointAmount;
          }
        } else if (element.billItemType === 2 && element.checked === false) {
          total -= unMaskPrice(element.returnPrice) * element.quantity;
        } else if (element.billItemType === 3 && element.checked === true) {
          total +=
            unMaskPrice(element.returnPrice) * element.quantity -
            element.allotmentDiscount -
            element.allotmentPointAmount;
        }
      }
      return total;
    },
  },
  created() {
    this.getListEmployee();
    this.fetchAccountant();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu trả hàng', route: '/returnBills' },
      { title: 'Danh sách phiếu trả hàng', route: '/returnBills' },
      { title: 'Cập nhật phiếu trả hàng' },
    ]);
    this.billNumber = this.$route.query.id;
    this.getBillById();
  },
};
</script>

<style>
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
  z-index: 10000001;
  font-weight: 400;
  background: white;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
